import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "@mui/material";

import Navbar from "./components/Navbar.component";
import HomePage from "./pages/HomePage";
import LibraryPage from "./pages/LibraryPage";
import BookPage from "./pages/BookPage";

import Footer from "./components/Footer.component";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Container sx={{ marginTop: "5rem" }}>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/library" element={<LibraryPage />} />
          <Route path="/library/:bookId" element={<BookPage />} />
        </Routes>
      </Container>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
