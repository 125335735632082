import axios from "axios";
import { createContext, useState, useEffect } from "react";

export const BooksContext = createContext();

export const BooksProvider = ({ children }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [allPlaylists, setAllPlaylists] = useState([]);

  const fetchPlaylists = async (channelIds, apiKeys) => {
    setLoading(true);
    setError(null);

    try {
      const playlists = [];

      for (let i = 0; i < channelIds.length; i++) {
        let nextPageToken = null;

        do {
          const response = await axios.get(
            `https://www.googleapis.com/youtube/v3/playlists`,
            {
              params: {
                part: "snippet",
                channelId: channelIds[i],
                key: apiKeys[i],
                maxResults: 50,
                pageToken: nextPageToken,
              },
            }
          );

          playlists.push(...response.data.items);
          nextPageToken = response.data.nextPageToken;
        } while (nextPageToken);
      }

      // Assuming 'playlists' is the array of playlists you provided
      playlists.sort((a, b) => {
        const dateA = new Date(a.snippet.publishedAt);
        const dateB = new Date(b.snippet.publishedAt);
        return dateB - dateA;
      });

      setAllPlaylists(playlists);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlaylists(
      [
        process.env.REACT_APP_YOUTUBE_CHANNEL_ID,
        process.env.REACT_APP_SECOND_YOUTUBE_CHANNEL_ID,
        process.env.REACT_APP_RDG_YOUTUBE_CHANNEL_ID,
      ],
      [
        process.env.REACT_APP_YOUTUBE_API_KEY,
        process.env.REACT_APP_SECOND_YOUTUBE_API_KEY,
        process.env.REACT_APP_RDG_YOUTUBE_API_KEY,
      ]
    );
  }, []);

  return (
    <BooksContext.Provider
      value={{
        error,
        loading,
        allPlaylists,
      }}
    >
      {children}
    </BooksContext.Provider>
  );
};
