import ReactPlayer from "react-player";

import {
  Avatar,
  Box,
  Button,
  Divider,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";

import IntroVideo from "../assets/introVideo/intro.mov";

import SainiAudiobookLogo from "../assets/dkaudiobooks.png";
import DKSaini from "../assets/DKSaini.jpg";
import { Link } from "react-router-dom";

import "./HomePage.css";
import { useEffect, useRef } from "react";

const HomePage = () => {
  // ======================================
  const imageListRef = useRef(null);

  useEffect(() => {
    const scrollContainer = imageListRef.current;
    const scrollHeight = scrollContainer.scrollHeight;
    const scrollDuration = 80000; // Adjust the scroll duration as needed

    const scrollStep = scrollHeight / (scrollDuration / 15);
    let scrollCount = 0;
    const scrollInterval = setInterval(() => {
      if (scrollCount < scrollHeight) {
        scrollContainer.scrollTop += scrollStep;
        scrollCount += scrollStep;
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);
  // ======================================

  const images = importAll(
    require.context("../assets/writers", false, /\.(png|jpe?g|svg)$/)
  );

  function importAll(r) {
    return r.keys().map(r);
  }
  console.log("images");
  return (
    <Box marginTop={4}>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={{ xs: 2, md: 4 }}
        paddingX={0}
      >
        <Typography
          className="animate-character"
          variant={"h3"}
          fontWeight="bold"
          align="center"
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "1.8rem",
            },
            "@media (min-width: 601px) and (max-width: 900px)": {
              fontSize: "3rem",
            },
            "@media (min-width: 901px)": {
              fontSize: "4rem",
            },
          }}
        >
          Punjabi Audio Books Library <br /> by <br />
          Devinder Kaur &nbsp; 'D.Saini'
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Divider />
          <Divider />
        </Box>

        <Box
          sx={{
            width: { xs: "100%", md: "80%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on medium and above
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Avatar
              src={DKSaini}
              sx={{
                width: { xs: "10rem", md: 280 },
                height: { xs: "10rem", md: 280 },
                border: "2px solid black",
                boxShadow: "0px 18px 60px rgba(255, 87, 43, 0.5);",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 18px 60px rgba(255, 87, 43, 2.5);",
                },
              }}
            />
            <Avatar
              src={SainiAudiobookLogo}
              sx={{
                width: { xs: "10rem", md: 280 },
                height: { xs: "10rem", md: 280 },
                border: "2px solid black",
                boxShadow: "0px 18px 60px rgba(255, 87, 43, 0.5);",
                transition: "box-shadow 0.3s",
                "&:hover": {
                  boxShadow: "0px 18px 60px rgba(255, 87, 43, 2.5);",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              textAlign: { xs: "center", md: "left" },
              width: "100%",
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              fontWeight="bold"
              variant="h2"
              gutterBottom
            >
              {process.env.REACT_APP_API_AUDIO_COUNT}k+
              <Typography align="center" fontWeight="bold">
                AUDIOS
              </Typography>
            </Typography>
            <Typography
              align="center"
              fontWeight="bold"
              variant="h2"
              gutterBottom
            >
              {process.env.REACT_APP_API_BOOK_COUNT}+
              <Typography align="center" fontWeight="bold">
                BOOKS
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Divider />
          <Divider />
        </Box>

        <Box sx={{ display: "flex", gap: 2, marginBottom: "1rem" }}>
          <Button size="large" variant="contained" color="warning">
            <Link
              to={`https://www.youtube.com/channel/${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}?sub_confirmation=1`}
              style={{
                color: "white",
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              visit Youtube
            </Link>
          </Button>
          <Button size="large" variant="contained" color="primary">
            <Link
              to="/library"
              style={{
                color: "white",
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              📚LIBRARY📚
            </Link>
          </Button>
        </Box>

        <Divider
          sx={{
            width: "100%",
          }}
        >
          {/* <Chip label="Introduction" size="small" /> */}
          <Typography variant="h4" fontWeight="bold">
            Introduction
          </Typography>
        </Divider>
        <div
          style={{
            width: "100%", // Adjust the width as needed
            marginBottom: "1rem",
          }}
        >
          <ReactPlayer url={IntroVideo} controls width="100%" height="100%" />
        </div>

        <Divider
          sx={{
            width: "100%",
          }}
        >
          {/* <Chip label="Our Prominent Writers" size="medium" /> */}
          <Typography variant="h4" fontWeight="bold">
            Our Prominent Writers
          </Typography>
        </Divider>
        <ImageList
          ref={imageListRef}
          sx={{ width: "100%", height: { xs: "50vh", md: "80vh" } }}
          cols={5}
          gap={8}
        >
          {images.map((path, index) => (
            <ImageListItem key={index}>
              <img
                className="writer-image"
                src={path}
                alt={index}
                loading="lazy"
                style={{ objectFit: "cover" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
    </Box>
  );
};

export default HomePage;
