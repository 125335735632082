import { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ListItemButton,
  Stack,
  Link,
  Container,
  Avatar,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Facebook,
  Twitter,
  YouTube,
  Instagram,
  HighlightOff,
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { deepOrange } from "@mui/material/colors";

import SainiAudiobookLogo from "../assets/dkaudiobooks.png";

const Navbar = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleSearchOpenState = (bool) => {
    setOpenSearch(bool);
  };
  const toggleDrawer = (open) => {
    setOpenDrawer(open);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openSearch) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openSearch]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          padding: 0,
          bgcolor: deepOrange[500],
        }}
      >
        <Toolbar
          component={Container}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <IconButton
            color="inherit"
            sx={{ display: "flex", border: "1px solid white" }}
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          <Avatar
            src={SainiAudiobookLogo}
            component={RouterLink}
            to="/"
            sx={{ width: 38, height: 38, border: "2px solid white" }}
          />

          <Typography
            variant="h4"
            component={RouterLink}
            to="/"
            sx={{
              display: { xs: "none", md: "flex" },
              color: "white",
              textDecoration: "none",
              alignItems: "center",
            }}
          >
            SAINIAUDIOBOOKS
          </Typography>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <Link
              component={RouterLink}
              to="https://www.facebook.com/PunjabiwithDkSaini/"
              color="inherit"
              sx={{
                textDecoration: "none",
                border: "1px solid white",
                borderRadius: "100%",
                "&:hover": { transform: "scale(1.2)" },
              }}
            >
              <IconButton color="inherit">
                <Facebook />
              </IconButton>
            </Link>

            <Link
              component={RouterLink}
              to="https://twitter.com/devinderKaurSa2"
              color="inherit"
              sx={{
                textDecoration: "none",
                border: "1px solid white",
                borderRadius: "100%",
                "&:hover": { transform: "scale(1.2)" },
              }}
            >
              <IconButton
                color="inherit"
                sx={{ "&:hover": { transform: "scale(1.1)" } }}
              >
                <Twitter />
              </IconButton>
            </Link>

            <Link
              component={RouterLink}
              to="https://www.instagram.com/devinderksaini/"
              color="inherit"
              sx={{
                textDecoration: "none",
                border: "1px solid white",
                borderRadius: "100%",
                "&:hover": { transform: "scale(1.2)" },
              }}
            >
              <IconButton
                color="inherit"
                sx={{ "&:hover": { transform: "scale(1.1)" } }}
              >
                <Instagram />
              </IconButton>
            </Link>

            <Link
              component={RouterLink}
              to="https://www.youtube.com/@PunjabiAudiobooksByDKSaini"
              color="inherit"
              sx={{
                textDecoration: "none",
                border: "1px solid white",
                borderRadius: "100%",
                "&:hover": { transform: "scale(1.2)" },
              }}
            >
              <IconButton
                color="inherit"
                sx={{ "&:hover": { transform: "scale(1.1)" } }}
              >
                <YouTube />
              </IconButton>
            </Link>
          </Box>

          {/* <IconButton
            color="inherit"
            onClick={() => handleSearchOpenState(true)}
            sx={{ "&:hover": { transform: "scale(1.1)" } }}
          >
            <Search />
          </IconButton> */}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{
            width: { xs: "80vw", md: "20vw" },
            height: "100vh",
            display: "flex",
            backgroundColor: "#fffbf3",
          }}
          role="presentation"
          onClick={() => toggleDrawer(false)}
        >
          <Box
            flex={2}
            bgcolor="#252525"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Avatar
              src={SainiAudiobookLogo}
              component={RouterLink}
              to="/"
              sx={{
                marginTop: 1,
                width: 45,
                height: 45,
                border: "2px solid white",
              }}
            />
            <Stack height="100%" justifyContent="flex-end">
              <Link
                component={RouterLink}
                to="https://www.facebook.com/PunjabiwithDkSaini/"
              >
                <IconButton
                  sx={{
                    color: "#1877F2",
                    bgcolor: "white",
                    borderRadius: 0,
                    margin: 2,
                    "&:hover": {
                      bgcolor: "#f0f0f0", // Change the background color on hover
                      transform: "scale(1.1)", // Scale up on hover
                    },
                  }}
                >
                  <Facebook />
                </IconButton>
              </Link>
              <Link
                component={RouterLink}
                to="https://twitter.com/devinderKaurSa2"
              >
                <IconButton
                  sx={{
                    color: "#1DA1F2",
                    bgcolor: "white",
                    borderRadius: 0,
                    margin: 2,
                    "&:hover": {
                      bgcolor: "#f0f0f0", // Change the background color on hover
                      transform: "scale(1.1)", // Scale up on hover
                    },
                  }}
                >
                  <Twitter />
                </IconButton>
              </Link>
              <Link
                component={RouterLink}
                to="https://www.youtube.com/@PunjabiAudiobooksByDKSaini"
              >
                <IconButton
                  sx={{
                    color: "#FF0000",
                    bgcolor: "white",
                    borderRadius: 0,
                    margin: 2,
                    "&:hover": {
                      bgcolor: "#f0f0f0", // Change the background color on hover
                      transform: "scale(1.1)", // Scale up on hover
                    },
                  }}
                >
                  <YouTube />
                </IconButton>
              </Link>
              <Link
                component={RouterLink}
                to="https://www.instagram.com/devinderksaini"
              >
                <IconButton
                  sx={{
                    color: "#FF0000",
                    bgcolor: "white",
                    borderRadius: 0,
                    margin: 2,
                    "&:hover": {
                      bgcolor: "#f0f0f0", // Change the background color on hover
                      transform: "scale(1.1)", // Scale up on hover
                    },
                  }}
                >
                  <Instagram />
                </IconButton>
              </Link>
            </Stack>
          </Box>

          <Box flex={8}>
            <List>
              <Link
                component={RouterLink}
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary="HOME" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Divider />
              <Link
                component={RouterLink}
                to="/library"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary="LIBRARY" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Divider />
            </List>
          </Box>
          <IconButton
            color="inherit"
            sx={{
              marginTop: 1,
              width: 50,
              height: 50,
              border: "2px solid white",
              bgcolor: "transparent",
            }}
            onClick={() => toggleDrawer(false)}
          >
            <HighlightOff sx={{ width: 45, height: 45 }} />
          </IconButton>
        </Box>
      </Drawer>

      <Dialog
        open={openSearch}
        onClose={() => handleSearchOpenState(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description">
            {[...new Array(50)]
              .map(
                () =>
                  `Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.`
              )
              .join("\n")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSearchOpenState(false)}>Cancel</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
