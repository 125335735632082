import * as React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { Container } from "@mui/material";

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#333",
        color: "white",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <Container>
        <Typography variant="h4" gutterBottom>
          Stay Connected
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 2,
            gap: 1,
          }}
        >
          <Link
            href="https://www.facebook.com/PunjabiwithDkSaini/"
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="none"
            sx={{
              border: "1px solid white",
              borderRadius: "100%",
              "&:hover": { transform: "scale(1.2)" },
            }}
          >
            <IconButton color="inherit">
              <Facebook />
            </IconButton>
          </Link>
          <Link
            href="https://twitter.com/devinderKaurSa2"
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="none"
            sx={{
              border: "1px solid white",
              borderRadius: "100%",
              "&:hover": { transform: "scale(1.2)" },
            }}
          >
            <IconButton color="inherit">
              <Twitter />
            </IconButton>
          </Link>
          <Link
            href="https://www.instagram.com/devinderksaini/"
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="none"
            sx={{
              border: "1px solid white",
              borderRadius: "100%",
              "&:hover": { transform: "scale(1.2)" },
            }}
          >
            <IconButton color="inherit">
              <Instagram />
            </IconButton>
          </Link>
          <Link
            href="https://www.youtube.com/@PunjabiAudiobooksByDKSaini"
            target="_blank"
            rel="noopener"
            color="inherit"
            underline="none"
            sx={{
              border: "1px solid white",
              borderRadius: "100%",
              "&:hover": { transform: "scale(1.2)" },
            }}
          >
            <IconButton color="inherit">
              <YouTube />
            </IconButton>
          </Link>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" gutterBottom>
            &copy; {new Date().getFullYear()} sainiaudiobooks&nbsp; All rights
            reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
