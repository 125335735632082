import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Fade,
  Paper,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BookCard from "../components/BookCard.component";
import { BooksContext } from "../contexts/BooksContext";
import { KeyboardArrowUp } from "@mui/icons-material";
import { deepOrange } from "@mui/material/colors";

const LibraryPage = () => {
  const { loading, allPlaylists } = useContext(BooksContext);
  // +++++++++++++++++++++++++++++++++
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // +++++++++++++++++++++++++++++++++

  const categories = [
    "ALL",
    "ਓਸ਼ੋ",
    "NOVEL",
    "STORY",
    "PROSE",
    "BIOGRAPHY",
    "SAFARNAMA",
    "POETRY",
    "REKHA CHITAR",
    // "LEARN",
  ];

  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [searchInput, setSearchInput] = useState("");

  const handleTabChange = (event, newValue) => {
    setPage(0);
    setSelectedCategory(newValue);
  };

  const handleInputChange = (newInputValue) => {
    setSearchInput(newInputValue);
  };

  const filteredPlaylists =
    selectedCategory === "ALL"
      ? allPlaylists.filter((playlist) =>
          playlist.snippet.title
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        )
      : allPlaylists.filter(
          (playlist) =>
            playlist.snippet.title
              .toLowerCase()
              .includes(selectedCategory.toLowerCase()) &&
            playlist.snippet.title
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        );
  // +++++++++++++++++++++++++++++++++++++++++++++++++++
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // +++++++++++++++++++++++++++++++++++++++++++++++++++

  return (
    <>
      <Fade in={isVisible}>
        <Paper
          elevation={24}
          onClick={scrollToTop}
          sx={{
            zIndex: 999,
            position: "fixed",
            bottom: "60px",
            right: "30px",
            borderRadius: "100%",
            border: "2px solid orange",
            padding: 0.5,
            width: "40px",
            height: "40px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <KeyboardArrowUp sx={{ color: deepOrange[500], fontSize: "3rem" }} />
        </Paper>
      </Fade>
      {/* SEARCH PLAYLIST */}
      <Box marginY={2}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          <span id="lbtop">Search For</span>
        </Typography>
        <Autocomplete
          freeSolo
          disableClearable
          options={allPlaylists.map((option) => option.snippet.title)}
          onChange={(e, value) => handleInputChange(value)}
          onInputChange={(e, value) => handleInputChange(value)}
          renderInput={(params) => (
            <TextField
              type="search"
              {...params}
              label="Enter book name / author name / book category"
              variant="outlined"
              fullWidth
              value={searchInput}
              sx={{ bgcolor: "#fff8eb" }}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          )}
        />
        {/* SELECT CATEGORY */}
        <Tabs
          value={selectedCategory}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginTop: 2 }}
        >
          {categories.map((category) => (
            <Tab key={category} label={category} value={category} />
          ))}
        </Tabs>
        <Divider />
      </Box>

      {/* <TablePagination
        component="div"
        count={filteredPlaylists.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      <Box sx={{ width: "100%", marginY: 2 }}>
        {/* PLAYLISTS */}
        {loading ? (
          <Box sx={{ height: "100vh" }}>
            <Backdrop sx={{ color: "#fff", zIndex: 100 }} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ) : (
          <>
            {filteredPlaylists.length > 0 ? (
              <Stack
                direction="row"
                flexWrap="wrap"
                marginY={2}
                justifyContent="space-around"
                alignItems="center"
              >
                {filteredPlaylists
                  .slice(rowsPerPage * page, rowsPerPage * (page + 1))
                  .map((playlist) => (
                    <BookCard key={playlist.id} bookData={playlist} />
                  ))}
              </Stack>
            ) : (
              <Typography variant="body1">Not found.</Typography>
            )}
          </>
        )}
      </Box>

      <TablePagination
        onClick={scrollToTop}
        component="div"
        count={filteredPlaylists.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default LibraryPage;
