import { AccessTime, ArrowRight, PlayCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

const fetchPlaylistItems = async (playlistId, apiKey) => {
  let nextPageToken = "";
  let allItems = [];

  try {
    do {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&key=${apiKey}&maxResults=50&pageToken=${nextPageToken}`
      );
      allItems = [...allItems, ...response.data.items];
      nextPageToken = response.data.nextPageToken;
    } while (nextPageToken);

    return allItems;
  } catch (error) {
    console.error("Error fetching playlist items:", error);
    return [];
  }
};

const fetchPlaylistInfo = async (playlistId, apiKey) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/playlists?part=snippet&id=${playlistId}&key=${apiKey}`
    );
    return response.data.items[0].snippet;
  } catch (error) {
    console.error("Error fetching playlist info:", error);
    return null;
  }
};

const BookPage = () => {
  const { bookId } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentBookData, setCurrentBookData] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({
    snippet: { resourceId: {} },
  });
  const [playlistTitle, setPlaylistTitle] = useState("");

  const handleVideoClick = (video) => {
    setCurrentVideo(video);
  };

  const handlePreviousVideo = () => {
    const currentIndex = currentBookData.findIndex(
      (video) => video.id === currentVideo.id
    );
    if (currentIndex > 0) {
      const previousVideo = currentBookData[currentIndex - 1];
      setCurrentVideo(previousVideo);
    }
  };

  const handleNextVideo = () => {
    const currentIndex = currentBookData.findIndex(
      (video) => video.id === currentVideo.id
    );
    if (currentIndex < currentBookData.length - 1) {
      const nextVideo = currentBookData[currentIndex + 1];
      setCurrentVideo(nextVideo);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [items, playlistInfo] = await Promise.all([
        fetchPlaylistItems(bookId, process.env.REACT_APP_YOUTUBE_API_KEY),
        fetchPlaylistInfo(bookId, process.env.REACT_APP_YOUTUBE_API_KEY),
      ]);
      setCurrentBookData(items);
      setPlaylistTitle(playlistInfo?.title || "Unknown Playlist");
      setLoading(false);

      // Set the first video to play automatically
      if (items.length > 0) {
        setCurrentVideo(items[0]);
      }
    };

    fetchData();
  }, [bookId]);
  // console.log(currentVideo);
  // console.log(currentBookData);

  return (
    <>
      {loading === true ? (
        <LinearProgress color="secondary" />
      ) : (
        <>
          <Paper elevation={1}>
            <Typography
              variant="h5"
              fontWeight="bold"
              align="center"
              sx={{
                fontSize: { md: "2rem" },
                border: "1px solid #333",
                borderRadius: ".4rem",
                backgroundColor: "#fff8eb",
              }}
              marginY={2}
              padding={1}
            >
              {playlistTitle}
            </Typography>
          </Paper>
          <Grid container spacing={2} justifyContent="center" marginBottom={5}>
            <Grid item xs={12} sm={12} md={8}>
              <Paper
                elevation={3}
                sx={{
                  border: "1px solid #333",
                  borderRadius: ".4rem",
                  backgroundColor: "#fff8eb",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    paddingTop: "56.25%",
                    marginBottom: "1rem",
                    width: "100%",
                  }}
                >
                  {currentVideo?.snippet?.title === "Private video" ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white background
                        color: "#333", // Dark text color
                        fontSize: "2rem", // Larger font size
                        fontWeight: "bold", // Bold text
                      }}
                    >
                      <AccessTime fontSize="large" sx={{ fontSize: "6rem" }} />
                    </Box>
                  ) : (
                    <ReactPlayer
                      url={`https://www.youtube.com/watch?v=${currentVideo?.snippet?.resourceId?.videoId}`}
                      controls
                      playing
                      width="100%"
                      height="100%"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                      config={{
                        youtube: {
                          playerVars: {
                            fs: 1, // Full Screen Button
                            rel: 0, // the player will show related videos that are from the same channel as the video that was just played.
                            playsinline: 1, // Play Video inline in the browser
                            origin: window.location.origin, // Set the origin to prevent referrer issues
                          },
                        },
                      }}
                      playIcon={
                        <PlayCircleOutline
                          sx={{
                            color: "white",
                            fontSize: "5rem",
                          }}
                        />
                      }
                      // light={`https://img.youtube.com/vi/${currentVideo?.snippet?.resourceId?.videoId}/maxresdefault.jpg`}
                    />
                  )}
                </div>
                <Typography variant="h6" fontWeight="bold" textAlign="center">
                  {currentVideo?.snippet?.title === "Private video"
                    ? "Upcoming Video"
                    : currentVideo?.snippet?.title}
                </Typography>
                <Grid container justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handlePreviousVideo}
                    sx={{ margin: 1 }}
                  >
                    PREV
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleNextVideo}
                    sx={{ margin: 1 }}
                  >
                    NEXT
                  </Button>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <List
                sx={{
                  border: "1px solid #333",
                  borderRadius: ".4rem",
                  bgcolor: "background.paper",
                  overflow: "auto",
                  maxHeight: "70vh", // Set a max height for the list
                  backgroundColor: "#fff8eb",
                }}
              >
                {currentBookData.map((video) => (
                  <ListItemButton
                    key={video.id}
                    onClick={() => handleVideoClick(video)}
                    sx={{
                      "&:hover": { backgroundColor: "aquamarine" },
                      borderBottom: "1px solid grey",
                      backgroundColor:
                        video.id === currentVideo.id ? "aquamarine" : "",
                    }}
                  >
                    {video.id === currentVideo.id && <ArrowRight />}
                    <ListItemText
                      primary={
                        video?.snippet?.title === "Private video"
                          ? "Upcoming Video"
                          : video?.snippet?.title
                      }
                      secondary={video.snippet.channelTitle}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BookPage;
