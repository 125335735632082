import { AccessTime } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const BookCard = ({ bookData }) => {
  return (
    <Link
      to={`${bookData.id}`}
      style={{ textDecoration: "none", color: "white" }}
    >
      <Card
        sx={{
          width: {
            xs: "95%",
            sm: 300,
            md: 355,
          },
          marginY: 2,
          borderRadius: "8px",
          backgroundColor: "#fff8eb",
          padding: 1,
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
        elevation={8}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "-12px",
              width: "calc(100% - 32px)",
              height: "100%",
              borderRadius: "8px",
              backgroundColor: "#e3c998",
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: "-6px",
              width: "calc(100% - 16px)",
              height: "100%",
              borderRadius: "8px",
              backgroundColor: "#c7af81",
            }}
          />
          <CardMedia
            component="img"
            height="200"
            image={bookData.snippet.thumbnails.high.url}
            alt={bookData.snippet.title}
            sx={{
              zIndex: "100",
              borderRadius: "8px",
            }}
          />
          {/* <Chip
            label="10 Videos"
            sx={{
              zIndex: "101",
              position: "absolute",
              bottom: "8px",
              right: "8px",
              borderRadius: "5px",
              backgroundColor: "#ffffffcf",
            }}
          /> */}
        </div>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            fontWeight="bold"
            component="div"
            sx={{
              fontSize: { xs: 19, sm: 20, md: 22 },
            }}
          >
            {bookData.snippet.title === "Private video"
              ? "Upcoming Book"
              : bookData.snippet.title.slice(0, 50)}
            ...
          </Typography>
          <Typography gutterBottom variant="p" fontWeight="bold">
            <Chip label={bookData.snippet.channelTitle} />
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button fullWidth variant="contained" color="warning">
            {bookData.snippet.thumbnails.high.url ===
            "https://i.ytimg.com/img/no_thumbnail.jpg" ? (
              <>
                Coming soon...
                <AccessTime />
              </>
            ) : (
              "WATCH NOW"
            )}
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
};

export default BookCard;
